.columns {
  @extend .is-flex;
  flex-wrap: wrap;
  &_space_between {
    justify-content: space-between;
  }
  .column {
    &:first-child {
      margin-left: 0;
    }
  }
}

.column {
  margin-left: 20px;
  &-1 {
    min-width: 300px;
    max-width: 300px;
  }
  &-1l {
    min-width: 390px;
    max-width: 390px;
  }
  &-2 {
    min-width: 490px;
    max-width: 490px;
  }
  &-2l {
    min-width: 590px;
    max-width: 590px;
  }
  &-3 {
    min-width: 650px;
    max-width: 650px;
  }
}