.header {
  box-shadow: 0 1px 2px rgba(37, 37, 37, 0.2);
  padding: 50px 0 30px;
  .container {
    position: relative;
  }
  .logo {
    position: absolute;
    top: -45px;
    z-index: 2;
  }
  .lang {
    font-weight: 300;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: -4px;
    .link {
      color: #54504d;
      &:hover, &.active {
        color: $brown;
      }
    }
    .active {
      font-weight: 700;
    }
  }
  .nav {
    justify-content: center;
    padding-left: 9%;
    &__link {
      color: #333333;
      font-size: 19px;
      &:hover, &.active {
        color: $brown;
      }
    }
  }
}

.nav {
  display: flex;
  flex: 1;
  &__link {
    display: inline-block;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.banner {
  &_index {
    background: #f9f9f9 url("#{$static_root}images/banner.png") no-repeat center;
    padding: 240px 0 30px;
  }
  &_projects {
    background: #f9f9f9 url("#{$static_root}images/banners/projects.jpg") no-repeat center;
  }
  &_structure {
    background: #f9f9f9 url("#{$static_root}images/banners/structure.jpg") no-repeat center;
    padding: 200px 0 248px;
    margin-bottom: 40px;
  }
  &_friends {
    background: #f9f9f9 url("#{$static_root}images/banners/friends.png") no-repeat center;
    padding: 200px 0 248px;
    margin-bottom: 40px;
  }
  &_project {
    &_readings {
      background: #f9f9f9 url("#{$static_root}images/banners/news.jpg") no-repeat center;
    }
    &_bust {
      background: #f9f9f9 url("#{$static_root}images/banner-bust.png") no-repeat center;
    }
    &_ball {
      background: #f9f9f9 url("#{$static_root}images/banner-ball.png") no-repeat center;
    }
    &_grant {
      background: #f9f9f9 url("#{$static_root}images/banner-grant.png") no-repeat center;
    }
  }
  &_about {
    background: #f9f9f9 url("#{$static_root}images/banners/about.jpg") no-repeat center;
    padding: 200px 0 248px;
    margin-bottom: 40px;
  }
  &_about-griboedov {
    background: #f9f9f9 url("#{$static_root}images/banners/about-griboedov.jpg") no-repeat center;
    padding: 200px 0 248px;
    margin-bottom: 40px;
  }
  &_dev {
    background: #f9f9f9 url("#{$static_root}images/dev-banner.png") no-repeat center -100px;
    padding: 250px 0 650px;
  }
  &_contacts {
    background: #f9f9f9 url("#{$static_root}images/map-bg.png") no-repeat center top;
    position: relative;
  }
  &_sub-pages {
    margin-bottom: 40px;
    padding: 240px 0 180px;
  }

  .container {
    text-align: center;
    p {
      color: $grey;
      font-size: 28px;
      line-height: 1.5;
      margin: 0 0 40px;
    }
    .font-pushkin {
      margin-top: 50px;
    }
  }
}

.main {
  color: #212121;
  font-size: 18px;
  line-height: 1.6;
  margin: 15px 0;
  .read-more {
    margin-top: 45px;
    text-align: center;
  }
}

.news {
  padding-bottom: 60px;
  border-bottom: 1px solid #ededed;
  h2 {
    text-align: center;
    margin: 30px 0;
  }
  &__date {
    color: #d8aa76;
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
  }
  &__text {
    color: #212121;
    font-size: 18px;
  }
}

.progs {
  padding: 20px 0 60px;
  .column {
    margin-bottom: 50px;
    margin-right: 40px;
    &:nth-child(2n + 1) {
      margin-left: 0;
    }
    &:nth-child(n+2) {
      margin-right: 0;
    }
  }
  h2 {
    text-align: center;
    margin: 30px 0 40px;
  }
  &__header {
    color: #524e4b;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 15px 0;
  }
  &__text {
    color: #212121;
    margin-bottom: 25px;
  }
}

.footer {
  background-color: #595653;
  color: #fff;
  .container {
    padding: 70px 20px;
  }
  .nav {
    font-size: 19px;
    margin-bottom: 60px;
    &__link {
      color: inherit;
      &:hover, &.active {
        color: $brown;
      }
    }
  }
  .font-pushkin {
    color: #fff;
  }
  .copy {
    font-size: 16px;
    margin-top: 60px;
  }
}

.map {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  background-image: linear-gradient(174deg, #ffffff 0%, #ffffff 23%, rgba(255, 255, 255, 0.84) 30%, rgba(255, 255, 255, 0.88) 46%, rgba(255, 255, 255, 0.79) 49%, rgba(255, 255, 255, 0) 67%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0) 100%);
  padding-top: 200px;
  padding-bottom: 140px;
  &__desc {
    padding: 45px;
    background: #fff;
    position: absolute;
    text-align: left;
    margin-top: 50px;

  }
  &__info {
    color: #333333;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .info {
    &__desc {
      color: #878787;
      font-size: 17px;
      line-height: 1.75;
      display: flex;
      i {
        margin-top: 1px;
        margin-right: 5px;
      }
    }
  }
}

.float-image {
  float: left;
  margin: 0 40px 20px 0;
}