.container .main.friends {
  .friends-form {
    display: flex;
    align-items: center;
    justify-content: center;
    .success {
      width: 406px;
      text-align: center;
      margin: 50px;
    }
    form {
      width: 406px;
      .intl-tel-input {
        width: 100%;
        ul li::before {
          display: none !important;
        }
      }

      button.send {
        color: white;
        font-weight: bold;
        background-color: #d8aa76;
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        .material-icons {
          margin-left: 20px;
        }
      }
    }

  }

  .mui-file {
    width: 406px;

    label {
      width: 100%;
      text-align: center;
      background-color: #e6e6e6;
    }
    &__info {
      text-transform: none;
    }
    &__input {
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

