@font-face {
  font-family: 'Pushkin';
  src: url('#{$static_root}fonts/Pushkin.woff2') format('woff2'),
  url('#{$static_root}fonts/Pushkin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$static_root}fonts/ProximaNova-Light.woff2') format('woff2'),
  url('#{$static_root}fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$static_root}fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('#{$static_root}fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$static_root}fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('#{$static_root}fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Swift';
  src: url('#{$static_root}fonts/Swift.woff2') format('woff2'),
  url('#{$static_root}fonts/Swift.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.4;
  color: $base;
}

.wrapper {
  max-width: 2560px;
  min-width: 1280px;
  margin: 0 auto;
}

.container {
  width: 1280px;
  margin: 0 auto;
  padding: 0 10px;
}

.is-flex {
  display: flex;
}

img {
  max-width: 100%;
}

h1 {
  color: #666666;
  font-size: 28px;
  line-height: 2.15;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
  &:after {
    content: '';
    display: block;
    height: 3px;
    width: 35%;
    margin: 15px auto 0;
    background-color: #d8aa76;
  }
}

h2 {
  color: #55514e;
  font-size: 28px;
  line-height: 2.15;
  text-transform: uppercase;
  margin: 0;
}

.link {
  cursor: pointer;
  text-decoration: none;
  &_style {
    &_button {
      display: inline-block;
      color: #55514e;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 2.85;
      padding: 0 45px;
      border: 2px solid $brown;
      &:hover {
        background-color: $brown;
        color: #fff;
      }
    }
  }
  &_readmore {
    display: inline-flex;
    align-items: center;
    &:hover {
      svg {
        fill: #fff;
      }
    }
    svg {
      width: 18px;
      height: 14px;
      margin-left: 10px;
      margin-top: -2px;
    }
  }
}

.font-pushkin {
  font-family: 'Pushkin';
  color: #666666;
  font-size: 24px;
  font-weight: bold;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    margin-bottom: 15px;
    &:before {
      content: '';
      display: inline-block;
      min-width: 23px;
      height: 20px;
      margin-top: 3px;
      margin-right: 10px;
      background: url("#{$static_root}images/list.png");
    }
  }
}

.bordered-images {
  border: 10px solid #f8f1e8;
  max-height: 400px;
  margin-bottom: 55px;
  img {
    width: calc(100% + 20px);
    max-width: none;
    position: relative;
    top: 27px;
    left: 27px;
  }
}